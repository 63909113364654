import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Columns, Container, Element, Heading, Section } from 'react-bulma-components'
import UserCompleteProfileForm from '../components/users/form/complete-profile-form'
import { useSessionRequest } from '../stores/session'
import { Link } from 'gatsby-plugin-react-i18next'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const SignUpAsProPage = () => {
  const [request] = useSessionRequest({
    key: 'post /users/complete-profile'
  })

  const { t } = useTranslation()

  return (
    <Layout>

      <Section size='medium'>
        <Container>
          <Columns centered gap={8} className='has-border-right' breakpoint='desktop'>
            <Columns.Column narrow>
              {
                request && request.status === 'success' ?
                  <>

                    <Heading size={5} textColor='dark' display='relative'>
                      <Element renderAs='span' textColor='success' style={{ position: 'absolute', left: '-1.25em' }}>✓ </Element>
                      {t('account:complete.confirmation.title')}
                    </Heading>
                    <p>
                      {t('account:complete.confirmation.thx')} <br />
                      {t('account:complete.confirmation.link.click')} <Link to='/login/'>{t('account:complete.confirmation.link.here')}</Link> {t('account:complete.confirmation.link.connect')}.
                    </p>
                  </>
                  :
                  <>
                    <Heading size={5} textColor='dark' mb={6}>
                      {t('account:complete.title')}
                    </Heading>
                    <UserCompleteProfileForm />
                  </>
              }
            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}
export default SignUpAsProPage

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`